(function (w, d) {
    w.promo_design_config.forEach(function (o) {
        var oBlockID = d.getElementById('tncms-block-' + o.block_id),
            oPromoPop = oBlockID.querySelector('#promo-designer-modal-custom-pop');
            shown_timestamp = localStorage.getItem('tnt_popState_' + o.block_id + '_shown');
        if (o.display_again != '') {
            try {
                (function () {
                    if (shown_timestamp != null) {
                        if ((parseInt(shown_timestamp) + (60 * 60 * 1000 * o.display_again) <= Date.now())) {
                            localStorage.removeItem('tnt_popState_' + o.block_id + '_shown');
                            shown_timestamp = null;
                        }
                    }
                })()
            } catch (error) {
                console.error(error);
            }
        };
        if (shown_timestamp == null) {
            if (o.display_triggers == 'exit_intent') {
                d.addEventListener("mouseout", function (event) {
                    event = event || w.event;
                    if ((event.clientX < 0) || (event.clientY < 0)) {
                        if (o.display_timer != '') {
                            setTimeout(function () {
                                oPromoPop.className += " visible";
                            }, (o.display_timer * 1000))
                        } else {
                            oPromoPop.className += " visible";
                        }
                    }
                })
                closePromo()
            }
            if (o.display_triggers == 'end_of_content') {
                w.addEventListener('scroll', function () {
                    if ((w.innerHeight + w.pageYOffset) >= d.body.offsetHeight - 2) {
                        if (o.display_timer != '') {
                            setTimeout(function () {
                                oPromoPop.className += " visible";
                            }, (o.display_timer * 1000))
                        } else {
                            oPromoPop.className += " visible";
                        }
                    }
                })
                closePromo()
            }
            if (o.display_triggers == 'visitor_enter') {
                if (o.display_timer != '') {
                    setTimeout(function () {
                        oPromoPop.className += " visible";
                    }, (o.display_timer * 1000))
                } else {
                    oPromoPop.className += " visible";
                }
                closePromo()
            }
            return false
        };
        function closePromo() {
            function hidePromo() {
                oPromoPop.className += " exit";
                setTimeout(function () {
                    oPromoPop.className += " hidden";
                }, 250)
            }
            if (o.display_again != '') {
                localStorage.setItem('tnt_popState_' + o.block_id + '_shown', Date.now());
            }
            var oPromoButton = oBlockID.querySelector('button.close');
            oPromoButton.addEventListener("click", hidePromo, false);
        };
    });
})(window, document);